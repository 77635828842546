<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学员缴费统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start;">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="培训工种" class="searchboxItem ci-full">
              <span class="itemLabel">培训工种:</span>
              <el-select v-model="retrievalData.occupationId" placeholder="请选择培训工种" clearable size="small">
                <el-option v-for="item in occupationList" :key="item.occupationId" :label="item.occupationName"
                  :value="item.occupationId">
                  <span style="float: left">{{ item.occupationName }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.trainLevel }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" clearable size="small" placeholder="请输入姓名" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" clearable size="small" placeholder="请输入手机号" />
            </div>
            <div title="招生老师" class="searchboxItem ci-full">
              <span class="itemLabel">招生老师:</span>
              <el-select v-model="retrievalData.channelId" placeholder="请选择招生老师" clearable size="small">
                <el-option v-for="item in channelIdList" :key="item.channelId" :label="item.contactUser"
                  :value="item.channelId">
                </el-option>
              </el-select>
            </div>
            <div title="工作单位" class="searchboxItem ci-full">
              <span class="itemLabel">工作单位:</span>
              <el-input v-model="retrievalData.workUnit" placeholder="请输入工作单位" type="text" clearable size="small" />
            </div>
          </div>
          <div class="searchbox">
            <div title="缴费状态" class="searchboxItem ci-full">
              <span class="itemLabel">缴费状态:</span>
              <el-select v-model="retrievalData.paymentState" placeholder="请选择缴费状态" size="small" clearable>
                <el-option v-for="item in paymentStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="缴费方式" class="searchboxItem ci-full">
              <span class="itemLabel">缴费方式:</span>
              <el-select v-model="retrievalData.paymentMethod" placeholder="请选择缴费方式" size="small" clearable>
                <el-option v-for="item in paymentMethodList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="开票状态" class="searchboxItem ci-full">
              <span class="itemLabel">开票状态:</span>
              <el-select v-model="retrievalData.invoiceState" placeholder="请选择开票状态" size="small" clearable>
                <el-option v-for="item in invoiceStateList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="缴费日期" class="searchboxItem">
              <span class="itemLabel">缴费日期:</span>
              <el-date-picker v-model="retrievalData.paymentDate" size="small" type="daterange" style="width: 5rem"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd"
                value-format="yyyy-MM-dd" />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportData">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="amountData"><span>缴费金额合计：{{ amountData.paymentMoney }}</span><span> 管理成本合计：{{ amountData.costMoney
            }}</span></div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60" fixed :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="150" />
              <el-table-column label="培训工种" align="left" show-overflow-tooltip prop="occupationName" width="150" />
              <el-table-column label="培训等级" align="left" show-overflow-tooltip prop="trainLevel" width="150">
                <template slot-scope="scope">
                  {{ $setDictionary("ET_TRAIN_LEVEL", scope.row.trainLevel) }}
                </template>
              </el-table-column>
              <el-table-column label="报名时间" align="left" show-overflow-tooltip prop="createTimeStr" width="160" />
              <el-table-column label="招生老师" align="left" show-overflow-tooltip prop="contactUser" width="100" />
              <el-table-column label="工作单位" align="left" show-overflow-tooltip prop="workUnit" width="120" />
              <el-table-column label="缴费状态" align="left" show-overflow-tooltip prop="paymentState" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_PAYMENT_STATE", scope.row.paymentState) }}
                </template>
              </el-table-column>
              <el-table-column label="缴费方式" align="left" show-overflow-tooltip prop="paymentMethod" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_APPLY_PAYMENT_METHOD_STATE", scope.row.paymentMethod) }}
                </template>
              </el-table-column>
              <el-table-column label="实收金额(元)" align="left" show-overflow-tooltip prop="paymentMoney" width="140" />
              <el-table-column label="管理成本(元)" align="left" show-overflow-tooltip prop="costMoney" width="140" />
              <el-table-column label="缴费日期" align="left" show-overflow-tooltip prop="paymentDate" width="160" />
              <el-table-column label="是否开票" align="left" show-overflow-tooltip prop="invoiceState" width="100">
                <template slot-scope="scope">
                  {{ $setDictionary("CT_USER_APPLY_PAYMENT_INVOICE_STATE", scope.row.invoiceState) }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "operate_dataCenterJDJG_studentPaymentStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        occupationId: "", // 培训工种
        userName: "", // 姓名
        mobile: "", // 手机号
        channelId: "", // 招生老师
        paymentState: "", // 缴费状态
        invoiceState: "", // 开票状态
        paymentMethod: "", // 缴费方式
        paymentDate: [], // 缴费日期
        workUnit: "",
      },
      // 培训工种 - 下拉数据
      occupationList: [],
      // 招生老师 - 下拉数据
      channelIdList: [],
      // 缴费状态 - 下拉数据
      paymentStateList: [],
      // 开票状态 - 下拉数据
      invoiceStateList: [],
      // 缴费方式 - 下拉数据
      paymentMethodList: [],
      // 合计数据
      amountData: {
        paymentMoney: "", // 缴费金额合计
        costMoney: "", // 管理成本合计
      }
    };
  },
  created() {
    this.getInvoice_type();
    this.searchCompOccupation();
    this.getCtChannelSelectList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    }),
  },
  methods: {
    // 获取 - 获取字典
    getInvoice_type() {
      // 缴费方式 
      let arr2 = this.$setDictionary("CT_USER_APPLY_PAYMENT_METHOD_STATE", "list");
      for (const key in arr2) {
        if (key != '40') {
          this.paymentMethodList.push({
            label: arr2[key],
            value: key
          });
        }
      }
      this.paymentMethodList.push({
        label: "其他",
        value: '40'
      })
      // 缴费状态
      let arr4 = this.$setDictionary("CT_USER_PAYMENT_STATE", "list");
      for (const key in arr4) {
        this.paymentStateList.push({
          label: arr4[key],
          value: key
        });
      }
      // 开票状态
      let arr5 = this.$setDictionary("CT_USER_APPLY_PAYMENT_INVOICE_STATE", "list");
      for (const key in arr5) {
        this.invoiceStateList.push({
          label: arr5[key],
          value: key
        });
      }
    },
    // 获取 - 培训工种
    searchCompOccupation() {
      this.$post('/biz/ct/occupation/select/pageList', {
        pageNum: 1,
        pageSize: 10,
        occupationName: ""
      }).then(ret => {
        this.occupationList = ret.data || []
      }).catch(err => {
        return;
      })
    },
    // 获取 - 培训老师
    getCtChannelSelectList() {
      this.$post('/biz/ct/channel/ctChannelSelectList', {
        contactUser: ''
      }).then(ret => {
        this.channelIdList = ret.data || [];
        this.channelIdList.unshift({
          contactUser: "自招",
          channelId: -1000,
        });
      }).catch(err => {
        console.log(err)
        return;
      })
    },
    // 获取 - 合计数据
    getAmountData(params) {
      this.$post('/biz/ct/datacenter/applyPaymentCount', params).then(res => {
        this.amountData.paymentMoney = res?.data?.paymentMoney ?? "";
        this.amountData.costMoney = res?.data?.costMoney ?? ""
      }).catch(err => {
        console.log(err)
        return;
      })
    },
    // 导出
    exportData() {
      let params = {};
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.workUnit) {
        params.workUnit = this.retrievalData.workUnit;
      }
      if (this.retrievalData.channelId) {
        params.channelId = this.retrievalData.channelId;
      }
      if (this.retrievalData.paymentState) {
        params.paymentState = this.retrievalData.paymentState;
      }
      if (this.retrievalData.invoiceState) {
        params.invoiceState = this.retrievalData.invoiceState;
      }
      if (this.retrievalData.paymentMethod) {
        params.paymentMethod = this.retrievalData.paymentMethod;
      }
      if (this.retrievalData.paymentDate !== null && this.retrievalData?.paymentDate.length) {
        params.startDate = this.retrievalData.paymentDate[0];
        params.endDate = this.retrievalData.paymentDate[1];
      }
      this.$post("/biz/ct/datacenter/applyPaymentListExport", params).then((res) => {
        if (res.status == "0") {
          let list = [res.data];
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 获取 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.occupationId) {
        params.occupationId = this.retrievalData.occupationId;
      }
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.workUnit) {
        params.workUnit = this.retrievalData.workUnit;
      }
      if (this.retrievalData.channelId) {
        params.channelId = this.retrievalData.channelId;
      }
      if (this.retrievalData.paymentState) {
        params.paymentState = this.retrievalData.paymentState;
      }
      if (this.retrievalData.invoiceState) {
        params.invoiceState = this.retrievalData.invoiceState;
      }
      if (this.retrievalData.paymentMethod) {
        params.paymentMethod = this.retrievalData.paymentMethod;
      }
      if (this.retrievalData.paymentDate !== null && this.retrievalData?.paymentDate.length) {
        params.startDate = this.retrievalData.paymentDate[0];
        params.endDate = this.retrievalData.paymentDate[1];
      }
      this.doFetch({
        url: "/biz/ct/datacenter/applyPaymentPageList",
        params,
        pageNum,
      });
      this.getAmountData(params);
    },
  },
};
</script>
<style lang="less" scoped>
.amountData {
  margin-bottom: 10px;

  span {
    margin-right: 20px;
  }
}
</style>